import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormModalOverlayRef } from 'src/app/shared/custome/custom-cdk-modal/classes/form-modal.ref';
import { ComponentModalService, FormModalConfig } from 'src/app/shared/custome/custom-cdk-modal/services/component-modal.service';
import { ApiService } from 'src/app/shared/services/api.service '; 
import { config } from 'src/app/shared/services/config'; 
import { OtpModalComponent } from 'src/app/shared/custome/modals/otp-modal/otp-modal.component';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2'; 
import { LocationService } from 'src/app/shared/helper/location.service';
import { NgxToasterService } from 'src/app/shared/helper/toasterNgs.service';
import { LocalStorageService } from 'src/app/shared/helper/local-storage.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  otpSubscription: Subscription;
  generatedOTPSubscription: Subscription;
  submitOTPSubscription: Subscription;
  getResendOTPSubscription: Subscription;
  resendOtpBehavSubscription: Subscription;
  public loginForm: FormGroup;
  otpToSend: any;
  isResendOtp: boolean = false;
  public show: boolean = false;
  public cnfshow: boolean = false; 
  lng:any;
  lat:any;
  @ViewChild("content",{static:true}) content:ElementRef; 
  constructor(private fb: FormBuilder,
    public router: Router,
     private authService: ApiService,
     config: NgbModalConfig,
     private store:LocalStorageService,
     private ngrxToaster: NgxToasterService,
      private _LocationService: LocationService,  
       private route: Router, private componentModalService: ComponentModalService ) { 
    this.loginForm = fb.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required],
      lat: [''],
      lng: [''], 
    });
    config.backdrop = 'static';
    config.keyboard = false;
    this.getOtpforLogin();
    this.resendOTP();
  }

  ngOnInit() { 
    setTimeout(() => {
      this._LocationService.seterLocation.subscribe((letLang: any) => {
        if (letLang) { 
          this.lat = letLang.lat;
          this.lng = letLang.long;
        }
      });
    }, 200);
  }

  showPassword() {
    this.show = !this.show;
  }

  showcnfPassword() {
    this.cnfshow = !this.cnfshow;
  }

  login() {
    const Data = this.loginForm.value;
    const payload = {
      username: Data.email,
      password: Data.password,
      latitude: this.lat,
      longitude: this.lng,
    };

    this.authService.postdata(payload, config.auth.login).subscribe((resp: any) => {
      switch (resp.responsecode) {
        case 3:
          this.store.setItem('user', resp);
          this.ngrxToaster.showInfo(resp.message, undefined); 
          this.route.navigate(['auth/changepwd']);
          break;
        case 2:
          this.store.setItem('userdetail', payload);
          this.store.setItem('usermob', resp);
          this.router.navigate([resp.data.secondary.length > 0 ? '/auth/choosenumber' : '/auth/verifyotp']);
          this.ngrxToaster.showInfo(resp.message, undefined); 
          break;
        case 1:
          if (resp.data?.permission) {
            // this.permissions.setPermissions(resp.data?.permission);
          }
          this.store.setItem('user', resp);
          this.ngrxToaster.showSuccess(resp.message, "Success");
          setTimeout(() => {
            this.router.navigate(['/dashboard/default']);
          }, 600);
          break;
        default:
          this.ngrxToaster.showError(resp.message, undefined);
          break;
      }
    });
  }

  forgetpwd() {
    this.router.navigate(['auth/resetpassword']);
  }

  generateOtpRequestModal() {
    const data = {
      otpMaxLength: 6,
      otpMinLength: 6,
      onlyNumbers: 'numbers',
      submitOtp: true,
      resendOtp: false,
      generatedOtp: 'credit'
    };

    const customConfig: FormModalConfig = {
      data: { action: data },
      isAnimation: {
        inAnimationStyle: 'slideInDown',
        outAnimationStyle: 'slideOutUp'
      },
    };

    this.componentModalService.open<OtpModalComponent>(OtpModalComponent, customConfig);
  }

  onOtpChange(value: any) {
    if (value.length === 6) {
      const Data = this.loginForm.value;
      const payload = {
        username: Data.email,
        password: Data.password,
        otp: value,
        latitude: this.lat,
        longitude: this.lng,
      };

      this.authService.postdata(payload, config.auth.verifyotp).subscribe((res: any) => {
        if (res.responsecode === 1) {
          this.store.setItem('user', res);
          // this.permissions.setPermissions(res.data?.permission);
          this.router.navigate(['/dashboard/default']);
        } else {
          Swal.fire({
            icon: 'error',
            title: res.message
          });
        }
      });
    }
  }

  getOtpforLogin() {
    this.authService.generatedOTP.subscribe((value: any) => {
      if (value) {
        this.otpToSend = value;
        this.onOtpChange(value);
      }
    });
  }

  resendOTP() {
    this.resendOtpBehavSubscription = this.authService.resendOTP.subscribe((value: any) => {
      // Handle resend OTP logic here if needed
    });
  }

  ngOnDestroy(): void {
    this.otpSubscription?.unsubscribe();
    this.submitOTPSubscription?.unsubscribe();
    this.resendOtpBehavSubscription?.unsubscribe();
    this.getResendOTPSubscription?.unsubscribe();
  }
}
